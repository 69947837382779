import * as React from "react"
import Layout from "../layouts/layout";
import VlinderForm from "../components/vlinderform";

const IndexPage = () => {
  return (
    <Layout pageTitle="Vlinderpakket bestellen" pageType="form">
      <VlinderForm/>
    </Layout>
  )
}

export default IndexPage
