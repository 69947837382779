import React from 'react';
import { navigate } from 'gatsby'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';

const VlinderForm = () => {

  const encode = (data) => {
    return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
  }

  return (
    <Formik
      enableReinitialize={
        true
      }
      initialValues={{
        Naam: '',
        woonplaats: '',
        telefoonnummer: '',
        email: '',
        rupsjes: '',
        habitat: '',
        extra: '',
        textComments: '',
      }}
      validationSchema={Yup.object({
        Naam: Yup.string().required('Naam is verplicht'),
        woonplaats: Yup.string().required('Woonplaats is verplicht'),
        telefoonnummer: Yup.string().required('Telefoonnummer is verplicht'),        
        email: Yup.string().email('Ongeldig e-mail adres').required('E-mailadres is verplicht'),
        rupsjes: Yup.string().required('Maak een keuze'),
        habitat: Yup.string().required('Maak een keuze'),
      })}      
      onSubmit={
        (values, actions ) => {
        setTimeout(() => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "Bestelling vlinderpakket", ...values })
          })
          .then(() => {
            navigate(
              "/bedankt-vlinder",
              {
                state: {values},
              }
            )
          })
          .catch(() => {
            alert('Error');
          })
          .finally(() => actions.setSubmitting(false))
        }, 400);
      }}
    >
    {() => (
      <Form name="Bestelling vlinderpakket" className="form form__vlinder" data-netlify="true">
        <fieldset>
          <legend>Contactgegevens</legend>
          <label htmlFor="Naam">
            Naam
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="Naam"/></span>
            <Field name="Naam" type="text"/>        
          </label>
          <label htmlFor="woonplaats">
            Woonplaats
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="woonplaats"/></span>
            <Field name="woonplaats" type="text"/>   
          </label>
          <label htmlFor="telefoonnummer">
            Telefoonnummer
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="telefoonnummer"/></span>
            <Field name="telefoonnummer" type="text"/>   
          </label>     
          <label htmlFor="email">
            E-mailadres
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="email"/></span>
            <Field name="email" type="email"/>  
          </label>
        </fieldset>

        <fieldset>
          <legend id='rupsjes'>Rupsjes <span className="required">*</span></legend>
          <ul aria-labelledby='rupsjes' role='group'>
            <li>
              <label>
              <span className="error-message"><ErrorMessage name="rupsjes"/></span>
                <div className="option">
                  <Field id="2distel" type="radio" name="rupsjes" value="2 distelvlinders" />
                  2 distelvlinders <span className="price">€ 8,00</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="5distel" type="radio" name="rupsjes" value="5 distelvlinders" />
                  5 distelvlinders <span className="price">€ 15,00</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="2koolwit" type="radio" name="rupsjes" value="2 koolwit rupsjes en een pop" />
                  2 koolwit rupsjes en een pop <span className="price">€ 5,00</span>
                </div>
              </label>
            </li>            
          </ul>
          <div className="extratext">Bij elke rupsjes-bestelling zit standaard een handleiding en een doeboekje</div>
        </fieldset>

        <fieldset>
          <legend id='habitat'>Observatie habitat (nodig voor pop/vlinder fase) <span className="required">*</span></legend>
          <ul aria-labelledby='habitat' role='group'>
            <li>
              <label>
              <span className="error-message"><ErrorMessage name="habitat"/></span>
                <div className="option">
                  <Field id="habitatklein" type="radio" name="habitat" value="klein" />
                  Kleine pop-up (voor max 2 pops) <span className="price">€ 8,00</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="habitatgroot" type="radio" name="habitat" value="groot" />
                  grote pop-up (voor 10-15 pops)<span className="price">€ 22,50</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="geenhabitat" type="radio" name="habitat" value="geen" />
                  Nee bedankt! (Je hebt er zelf al een)
                </div>
              </label>
            </li>            
          </ul>
          <div className="extratext">Bij elke habitat-bestelling zit een nectarbloem om je vlinders 1-2 dagen mee te voeden</div>
        </fieldset>

        <fieldset>
          <legend id='extra'>Extra leuks</legend>
          <ul aria-labelledby='extra' role='group'>
            <li>
              <label>
                <div className="option">
                  <Field id="boomstam" type="checkbox" name="extra" value="boomstam pop-houder" />
                  Boomstam pop-houder (voor de 5 distelvinder set)
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="zaadjesmengsel" type="checkbox" name="extra" value="vlinderbloemen zaadjesmengsel" />
                  Biologisch vlinderbloemen zaadjesmengsel - speciale Wild Kids editie!
                </div>
              </label>
            </li>           
          </ul>
        </fieldset>                

        <fieldset>
          <label htmlFor='Opmerking'>
            Opmerking
            <Field name="Opmerking" component="textarea"/>
          </label>
        </fieldset>

        <button type="submit">Bestellen</button>
      </Form>
    )}
    </Formik>
  )
}

export default VlinderForm